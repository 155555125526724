<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="5">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              <!--Détail de la Demande de Propositions-->
              <!--Détail de la Demande de Renseignement de Prix-->
              Détail
              {{ this.$store.getters["Auth/get_selected_doc"].document.documentType.description }}
            </h4>
            <v-spacer></v-spacer>
          <v-chip label small outlined :color="`${this.$store.getters['Auth/get_selected_doc'].document.free ? 'green' : 'red'}`">
          {{ `${this.$store.getters['Auth/get_selected_doc'].document.free ? "Gratuit" : "Payant"}` }}
        </v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Objet du dossier :</b>
            {{ this.$store.getters["Auth/get_selected_doc"].document.object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de publication du dossier :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.publiDate
                | moment("dddd, Do MMMM YYYY à hh:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure limite de dépôt des Offres/Propositions/Plis :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.deadline
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure d’ouverture des Offres/Propositions/Plis :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.date_opening
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <div
              v-if="
                this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 2 &&
                  this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 3 &&
                  this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 5
              "
            >
              <b>Nombre de Consultants/Cabinets retenus sur la liste restreinte • </b>
              {{ this.$store.getters["Auth/get_selected_doc"].document.selected_users.length }}
              <v-spacer class="my-1"></v-spacer>
              <b>Nombre de Manifestations d’Intérêts reçues • </b>
              {{
                this.$store.getters["Auth/get_selected_doc"].document.not_selected_users.length +
                  this.$store.getters["Auth/get_selected_doc"].document.selected_users.length
              }}
              <v-spacer class="my-1"></v-spacer>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-2" v-if="profile == 2">
          <v-card-title>
            <h4>Faire une demande d’éclaircissements</h4>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="request.objet" label="Objet de la demande d’éclaircissements"></v-text-field>
            <v-textarea
              name="input-7-1"
              v-model="request.content"
              label="Contenu de la demande d’éclaircissements"
              value="Décrivez le fichier"
            ></v-textarea>
            <v-file-input
              placeholder="Charger le fichier"
              label="Joindre le courrier/la lettre de la demande d’éclaircissements"
              multiple
              v-model="request.file"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" @click="addRequest">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>ENVOYER LA DEMANDE D’ECLAIRCISSEMENTS
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="this.$store.getters['Auth/get_selected_doc'].document.date_has_been_updated"
          class="mt-4"
        >
          <v-card-title>
            <v-chip>Un report a déja été effectué sur dossier</v-chip>
          </v-card-title>
        </v-card>
        <v-card class="pa-2 mt-4">
          <v-card-title>
            <h4>Liste des additifs rattachés au dossier</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item
                class="text-truncate"
                v-for="item in this.$store.getters['Auth/getDocAdd']"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="item.object"
                  ></v-list-item-title>
                  <v-list-item-subtitle class="text-truncate">{{
                    item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1" @click="ShowTheRevision(item.id)"
                      >mdi-eye</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-row>
          <v-col>
            <v-card
              class="mb-1"
              v-if="this.$store.getters['Auth/get_selected_doc'].document.notice"
            >
              <v-card-title>
                <h4>Référence de l’avis rattaché au dossier</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-list-item class="block text-truncate">
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text text-truncate font-weight-bold"
                        v-text="this.$store.getters['Auth/get_selected_doc'].document.notice.object"
                      ></v-list-item-title>

                      <v-list-item-subtitle class="text-truncate secondary--text">{{
                        this.$store.getters["Auth/get_selected_doc"].document.notice.publiDate
                          | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card v-if="!dialog">
          <v-card-title v-if="profile == 2">
            <h4>
              Aperçu
              {{ this.$store.getters["Auth/get_selected_doc"].document.documentType.description }}
            </h4>
            <v-spacer></v-spacer>
            <!-- {{this.$store.getters["Auth/get_user_access_doc"].data.isAcces}} -->
            <span v-if="!this.$store.getters['Auth/get_selected_doc'].document.free">
              <v-chip
                v-if="this.$store.getters['Auth/get_user_access_doc'].data.isAcces"
                label
                small
                color="gray"
              >
                {{ this.$store.getters["Auth/get_user_access_doc"].data.code }}
              </v-chip>
              <v-btn
                @click="RequestAccess()"
                v-if="!this.$store.getters['Auth/get_user_access_doc'].data.isAcces"
                class="primary"
              >
                Envoyer une demande paiement du dossier
              </v-btn>
            </span>
            <v-btn
              @click="downloadDoc()"
              v-if="this.$store.getters['Auth/get_selected_doc'].document.free"
              class="primary"
            >
              <v-icon>mdi-download</v-icon>
              Télécharger
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'doc' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'docx' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'xls' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'XLS' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_doc'].document.fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_doc'].document.fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
        <v-card v-if="dialog">
          <v-card-title>
            <!-- {{Tdr_Ob.fileLink.includes('doc')}} -->
            <h4>{{ Tdr_Ob.object }}</h4>
            <v-spacer></v-spacer>
            <h6>Date : {{ Tdr_Ob.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}</h6>
          </v-card-title>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <VueDocPreview
            v-if="
              Tdr_Ob.fileLink.includes('doc') ||
                Tdr_Ob.fileLink.includes('docx') ||
                Tdr_Ob.fileLink.includes('xls') ||
                Tdr_Ob.fileLink.includes('xlsx') ||
                Tdr_Ob.fileLink.includes('XLS') ||
                Tdr_Ob.fileLink.includes('XLSX')
            "
            :value="Tdr_Ob.fileLink"
            type="office"
          />
          <v-card-text>
            <v-row>
              <vue-friendly-iframe
                v-if="Tdr_Ob.fileLink.includes('pdf')"
                allow="true"
                :src="Tdr_Ob.fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";

export default {
  components: {
    VueDocPreview
  },
  props: {
    id: {
      id: Number,
      required: true
    }
  },
  data: () => ({
    filtered: [],
    dialog: false,
    Tdr_Ob: "",
    addi: {
      objet: "",
      publi_date: "",
      file: []
    },
    request: {
      objet: "",
      content: "",
      type: "",
      doc_id: "",
      file: ""
    },
    addi: {
      objet: "",
      publi_date: ""
    },
    ppm: [],
    additif: false,
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails du document",
        disabled: true
      }
    ]
  }),
  computed() {
    console.log(this.$store.getters["Auth/get_selected_doc"].documentType.title);
    if (this.$store.getters["Auth/get_selected_doc"].documentType.title == "DAOT") {
      //alert("show");
      this.additif = true;
    }
  },
  created() {
    let Virt = [];
    this.$store.dispatch("Auth/getDoc", this.id);
    this.$store.dispatch("Auth/user_access_doc", this.id);
    this.$store.dispatch("Auth/loadDocAdd", this.id);

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
  },
  methods: {
    ...mapActions({
      extension_addD: "Auth/extension_addD"
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getFiltered(tdr) {
      this.filtered = this.$store.getters["Auth/getDocAdd"].filter(function(value, index, arr) {
        return value.id == tdr;
      });
    },
    async ShowTheRevision(tdr) {
      let td = tdr;
      await this.getFiltered(td);
      this.Tdr_Ob = this.filtered[0];
      this.dialog = !this.dialog;
      console.log(this.Tdr_Ob);
      this.scrollToTop();
    },
    addRequest() {
      const data = new FormData();
      data.append("object", this.request.objet);
      data.append("content", this.request.content);
      data.append("request_type", 1);
      data.append("document", this.id);
      data.append("file", this.request.file[0]);
      this.$store.dispatch("Auth/requestAdd", data);

      this.request.objet = "";
      this.request.content = "";
      this.request.type = "";
      this.request.doc_id = "";
      this.request.file = [];
    },
    RequestAccess() {
      const data = new FormData();
      data.append("doc_id", this.id);
      this.$store.dispatch("Auth/DocAccessRequest", data);
    },
    downloadDoc() {
      const data = new FormData();
      data.append("id", this.id);
      this.$store.dispatch("Auth/downloadDoc", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
