<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="5">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              <!--
                {{ this.$store.getters["Auth/get_selected_doc"].document.documentType.description }}
              -->
              Détail du dossier concerné
            </h4>
            <v-spacer></v-spacer>
          <v-chip label small outlined :color="`${this.$store.getters['Auth/get_selected_doc'].document.free ? 'green' : 'red'}`">
          {{ `${this.$store.getters['Auth/get_selected_doc'].document.free ? "Gratuit" : "Payant"}` }}
        </v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Objet du dossier :</b>
            {{ this.$store.getters["Auth/get_selected_doc"].document.object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de publication du dossier :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.publiDate
                | moment("dddd, Do MMMM YYYY à hh:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure limite de dépôt des Offres/Propositions/Plis :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.deadline
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure d’ouverture des Offres/Propositions/Plis :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.date_opening
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <div
              v-if="
                this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 2 &&
                  this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 3 &&
                  this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 5
              "
            >
              <b>Nombre de Consultants/Cabinets retenus sur la liste restreinte • </b>
              {{ this.$store.getters["Auth/get_selected_doc"].document.selected_users.length }}
              <v-spacer class="my-1"></v-spacer>
              <b>Nombre de Manifestations d’Intérêts reçues • </b>
              {{
                this.$store.getters["Auth/get_selected_doc"].document.not_selected_users.length +
                  this.$store.getters["Auth/get_selected_doc"].document.selected_users.length
              }}
              <v-spacer class="my-1"></v-spacer>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-row>
          <v-col>
            <v-card
              class="mb-1"
              v-if="this.$store.getters['Auth/get_selected_doc'].document.notice"
            >
              <v-card-title>
                <h4>Référence de l’avis rattaché au dossier</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-list-item class="block text-truncate">
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text text-truncate font-weight-bold"
                        v-text="this.$store.getters['Auth/get_selected_doc'].document.notice.object"
                      ></v-list-item-title>

                      <v-list-item-subtitle class="text-truncate secondary--text">{{
                        this.$store.getters["Auth/get_selected_doc"].document.notice.publiDate
                          | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            <h4>

              Liste détaillée des demandes de paiement pour accéder au  dossier
              <!--Liste détaillée des demandes de paiement pour accéder au  dossier
                Liste des demandes d'access pour le
                {{ this.$store.getters["Auth/get_selected_doc"].document.documentType.description }}
              -->
            </h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-virtual-scroll
            :items="this.$store.getters['Auth/get_list_access_request'].request_acces"
            :item-height="50"
            height="400"
          >
            <template v-slot="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon large>mdi-toolbox-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.user.firstname + " " + item.user.lastname }}</v-list-item-title
                  >
                  <v-list-item-subtitle>{{ item.user.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn v-if="!item.active" @click="autoriseAccess(item)" depressed small>
                    Confirmation du paiement du dossier
                    <v-icon color="orange darken-4" right>mdi-check</v-icon>
                  </v-btn>
                  
                  <v-chip v-if="item.active"> Date de paiement du dossier : 
                    {{ item.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss") }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";

export default {
  components: {
    VueDocPreview
  },
  props: {
    id: {
      id: Number,
      required: true
    }
  },
  data: () => ({
    filtered: [],
    dialog: false,
    Tdr_Ob: "",
    addi: {
      objet: "",
      publi_date: "",
      file: []
    },
    request: {
      objet: "",
      content: "",
      type: "",
      doc_id: "",
      file: ""
    },
    addi: {
      objet: "",
      publi_date: ""
    },
    ppm: [],
    additif: false,
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails du document",
        disabled: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/loadAllAccessRequest", this.id);
    let Virt = [];
    this.$store.dispatch("Auth/getDoc", this.id);

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
  },
  methods: {
    ...mapActions({
      extension_addD: "Auth/extension_addD"
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getFiltered(tdr) {
      this.filtered = this.$store.getters["Auth/getDocAdd"].filter(function(value, index, arr) {
        return value.id == tdr;
      });
    },
    autoriseAccess(item) {
      const data = new FormData();
      data.append("doc_id", this.id);
      data.append("user_id", item.user.id);
      this.$store.dispatch("Auth/ActiveDocToUser", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
